<template>
  <FormModal
    title="Welk type warmtepomp wilt u?"
    subtitle="Dit zijn de opties die aansluiten bij uw wensen"
    show-back-btn
  >
    <template #body>
      <TypeOfHeatPumpFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatPumpFormStep from '~/components/form/steps/typeOfHeatPump/TypeOfHeatPumpFormStep'

export default {
  components: {
    TypeOfHeatPumpFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type warmtepomp',
      headDescription:
        'Geef aan naar wat voor type warmtepomp installatie u op zoek bent.',
      path: '/offertes-aanvragen/type-warmtepomp',
      progressValue: 75,
      checkoutStep: 5,
    }
  },
}
</script>
