<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <TypeOfHeatPumpFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TypeOfHeatPumpFormPart from 'chimera/heatPump/components/form/parts/typeOfHeatPump/TypeOfHeatPumpFormPart'
import NextStep from '~/pages/offertes-aanvragen/uw-gegevens'

export default {
  name: 'TypeOfHeatPumpFormStep',

  components: {
    TypeOfHeatPumpFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
